import React from 'react'
import Main from '../../components/Main'
// import Summary from '../../components/resume/Summary';

const Home = () => {
  return (
    // <Main component={<Summary />} />
    <Main />

    )
};
export default Home;