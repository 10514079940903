import React from 'react'
import Identification from './Identification'
import Info from './Info'
import './style.css'

const PersonalData = () => {
  return (
    <>
      <Identification />
      <Info />
      {/* <LinksContact /> */}
    </>
  )
}

export default PersonalData
